import React from "react";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/SEO/seo";
import TaxiRates from "../../../components/Taxi-Rates";
const TaxiRate = () => (
  <Layout>
    <SEO
      title="Taxi Price Hamburg to Kiel, Neumünster | Fixed Price Taxi"
      meta="Taxi Price Hamburg to Kiel, Neumünster | Fixed Price Taxi"
      description="We work on authentic and transparent terms and therefore, offer the right fare chart quoted with fixed Hamburg taxi prices. Now, ride without any worries!!"
    />
    <TaxiRates language="DE" />
  </Layout>
);

export default TaxiRate;
